import { faCubes, faDiagramSubtask } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslate } from '@tolgee/react';
import { Button } from 'react-bootstrap';
import { ActionButtons } from '../../../components/overview/ActionButtons';
import { setActionMode, setActiveContent } from '../../../context/appReducer';
import { setActiveComposition, setCreatedComposition } from '../../../context/compositionsReducer';
import { useAppDispatch } from '../../../context/hooks';
import { ActionModeType, ContentType } from '../../../models/models';
import { CompositionSimpleDto } from '../../../services/composer';

type CompositionActionButtonsProps = {
    composition: CompositionSimpleDto;
};

export const CompositionActionButtons = ({composition}: CompositionActionButtonsProps) => {
	const { t } = useTranslate();
    const dispatch = useAppDispatch();

    function onWorkingArea(composition: CompositionSimpleDto) {
		dispatch(setActiveComposition(composition));
		dispatch(setCreatedComposition(null));
		dispatch(setActiveContent(ContentType.editor));
	}

	function onCraneView(composition: CompositionSimpleDto) {
		dispatch(setActiveComposition(composition));
		dispatch(setCreatedComposition(null));
		dispatch(setActiveContent(ContentType.viewer));
	}

	/**
	 * Selectes the composition and opens the delete modal
	 */
	const onDelete = (composition: CompositionSimpleDto) => {
		dispatch(setActiveComposition(composition));
		dispatch(setActionMode(ActionModeType.Delete));
	};

	/**
	 * Selects the composition and opens the details modal
	 */
	const onEdit = (composition: CompositionSimpleDto) => {
		dispatch(setActiveComposition(composition));
		dispatch(setActionMode(ActionModeType.Details));
	};

	return (
		<>
			<Button variant='outline-success' size='sm' className='flex items-center gap-1 border-2' onClick={() => onWorkingArea(composition)}>
				{t('workArea')}
				<FontAwesomeIcon icon={faDiagramSubtask} />
			</Button>
			<Button variant='outline-warning' size='sm' className='flex items-center gap-1 border-2' onClick={() => onCraneView(composition)}>
				{t('craneView')}
				<FontAwesomeIcon icon={faCubes} />
			</Button>
			<ActionButtons onEdit={() => {onEdit(composition)}} onDelete={() => {onDelete(composition)}} />
		</>
	);
};
