import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCaretDown, faCaretRight } from '@fortawesome/pro-regular-svg-icons';
import { faCaretDown as faCaretDownSolid, faCaretRight as faCaretRightSolid } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { TablePropertyDto, PropertyDiscriminator, PropertyDto, ObjectPropertyDto, CalculatedPropertyDto } from '../../../../services/catalog';
import {
	CalculatedPropertyValue,
	PropertyCollectionDto,
	PropertyEntryDto,
	PropertySetEntryDto,
	PropertyUsageType,
	SimplePropertyDto,
} from '../../../../services/warehouse';
import TablePropertyGrid from './TablePropertyGrid';
import PropertyGridProperty from './PropertyGridProperty';
import ObjectPropertyGrid from './objectProperties/ObjectPropertyGrid';
import { Tooltip } from '@mui/material';
import { useTranslate } from '@tolgee/react';

type PropertyGridPSetProps = {
	editMode: boolean;
	selectedPropertyId: string;
	propertySet: PropertySetEntryDto;
	selectedPropertyType: PropertyUsageType;
	onPropertySelect: (value: PropertyDto | null) => void;
	onValueChanged: (value: PropertyEntryDto) => void;
	getData: (data: boolean) => void;
};

/**
 * show table row with propertyset name similiar to a header
 * can expand list of properties in this propertyset
 *
 * editMode: indicates if editMode is active then the input fields will be shown
 * selectedPropertyId: Id of selected property to highlight this property
 * propertySet: the propertySet
 */
const PropertyGridPSet = ({
	editMode,
	selectedPropertyId,
	propertySet,
	selectedPropertyType,
	onPropertySelect,
	onValueChanged,
	getData,
}: PropertyGridPSetProps) => {
	const { t } = useTranslate();
	const [expanded, setExpanded] = useState<boolean>(true);

	const onSelect = (property: PropertyDto) => {
		if (selectedPropertyId === property.id) {
			onPropertySelect(null);
		} else {
			onPropertySelect(property);
		}
	};

	return (
		<>
			<tr onClick={() => setExpanded(!expanded)} className='propertygrid-set-header'>
				<td colSpan={4}>
					<FontAwesomeIcon
						className='fa-xs px-2'
						icon={(expanded ? faCaretDown : faCaretRight) as IconProp}
					/>
					<FontAwesomeIcon
						className='fa-xs px-2'
						icon={(expanded ? faCaretDownSolid : faCaretRightSolid) as IconProp}
					/>
					{propertySet.name}
				</td>
			</tr>
			{expanded &&
				propertySet.propertyEntries?.map((entry: PropertyEntryDto) => {
					if (entry.property?.propertyDiscriminator === PropertyDiscriminator.Table) {
						return (
							<tr key={entry.property.id} style={{ width: '100%' }}>
								<td colSpan={4}>
									<TablePropertyGrid
										propertyEntry={entry}
										editMode={editMode && entry.propertyUsageType === selectedPropertyType}
										onChange={onValueChanged}
										property={entry.property as TablePropertyDto}
										getData={getData}
									/>
								</td>
							</tr>
						);
					}
					if (entry.property?.propertyDiscriminator === PropertyDiscriminator.Object) {
						return (
							<tr key={entry.property.id} style={{ width: '100%' }}>
								<td colSpan={4}>
									<ObjectPropertyGrid
										propertyEntry={entry}
										editMode={editMode}
										onChange={onValueChanged}
										property={entry.property as ObjectPropertyDto}
									/>
								</td>
							</tr>
						);
					}
					if (entry.property?.propertyDiscriminator === PropertyDiscriminator.Calculated) {
						const calculatedProperty = entry.property as CalculatedPropertyDto;
						const calculatedPropertyValue = entry.value as CalculatedPropertyValue;
						return (
							<Tooltip title={calculatedProperty.formula !== "" ? `${t("formula")}: ${calculatedProperty.formula}` : ""}>
								<tr className={`propertygrid-property`}>
									<td className='property-cell-name'>
										<div className='property-name'>{calculatedProperty.name ?? ""}</div>
										<div className='property-type-indicator'>{entry.propertyUsageType ? 'I' : 'T'}</div>
									</td>

									<td>
										{calculatedPropertyValue.value ? calculatedPropertyValue.value : calculatedProperty.formula}
									</td>
								</tr>
							</Tooltip>
						)
					}
					return (
						<PropertyGridProperty
							key={entry.property?.id}
							property={entry.property as SimplePropertyDto}
							selected={entry.property?.id! === selectedPropertyId}
							editMode={editMode && entry.propertyUsageType === selectedPropertyType}
							propertyEntry={entry}
							onClick={() => onSelect(entry.property as PropertyDto)}
							onChange={onValueChanged}
							selectedPropertyType={selectedPropertyType}
						/>
					);
				})}
		</>
	);
};

export default PropertyGridPSet;
