/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ComponentDetailsDto } from '../models/ComponentDetailsDto';
import type { ComponentSimpleDto } from '../models/ComponentSimpleDto';
import type { ConnectorInstanceDetailsDto } from '../models/ConnectorInstanceDetailsDto';
import type { CreateComponentDto } from '../models/CreateComponentDto';
import type { ElementInstanceDto } from '../models/ElementInstanceDto';
import type { PropertyCollectionDto } from '../models/PropertyCollectionDto';
import type { UpdateComponentDto } from '../models/UpdateComponentDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ComponentsService {

    /**
     * Gets component by given id.
     * @param componentId Identifier of the component
     * @returns ComponentDetailsDto Success
     * @throws ApiError
     */
    public static getComponent(
componentId: string,
): CancelablePromise<ComponentDetailsDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/components/{componentId}',
            path: {
                'componentId': componentId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Deletes an existing  component
     * @param componentId Delete component input
     * @returns void 
     * @throws ApiError
     */
    public static deleteComponent(
componentId: string,
): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/components/{componentId}',
            path: {
                'componentId': componentId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Gets all Components without filter
     * @returns ComponentSimpleDto Success
     * @throws ApiError
     */
    public static getComponents(): CancelablePromise<Array<ComponentSimpleDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/components',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Creates new component
     * @param requestBody Create component body
     * @returns ComponentSimpleDto Created
     * @throws ApiError
     */
    public static createComponent(
requestBody?: CreateComponentDto,
): CancelablePromise<ComponentSimpleDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/components',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Updates an existing component
     * @param requestBody Update component input
     * @returns ComponentSimpleDto Created
     * @throws ApiError
     */
    public static updateComponent(
requestBody?: UpdateComponentDto,
): CancelablePromise<ComponentSimpleDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/components',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Gets the properties with values of a component
     * @param componentId 
     * @returns PropertyCollectionDto Success
     * @throws ApiError
     */
    public static getComponentProperties(
componentId: string,
): CancelablePromise<PropertyCollectionDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/components/{componentId}/properties',
            path: {
                'componentId': componentId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Updates the properties of a component
     * @param componentId The id of the component
     * @param requestBody The properties to update
     * @returns void 
     * @throws ApiError
     */
    public static updateComponentProperties(
componentId: string,
requestBody?: PropertyCollectionDto,
): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/components/{componentId}/properties',
            path: {
                'componentId': componentId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get the elements that depend on the component
     * @param componentId 
     * @returns string Success
     * @throws ApiError
     */
    public static getDependencies(
componentId: string,
): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/components/{componentId}/dependencies',
            path: {
                'componentId': componentId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Creates a new instance for the given component id.
     * @param id The id of the component for which a new instance should be created.
     * @returns ElementInstanceDto Created
     * @throws ApiError
     */
    public static getComponentsInstancefor(
id: string,
): CancelablePromise<ElementInstanceDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/components/instancefor/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @param componentId 
     * @param modelName 
     * @param requestBody 
     * @returns ConnectorInstanceDetailsDto Success
     * @returns string Created
     * @throws ApiError
     */
    public static updateGeometry(
componentId: string,
modelName: string,
requestBody?: Array<ConnectorInstanceDetailsDto>,
): CancelablePromise<Array<ConnectorInstanceDetailsDto> | string> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/components/{componentId}/geometry/{modelName}',
            path: {
                'componentId': componentId,
                'modelName': modelName,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }

}
