import { Spinner } from '@lbc-toolkit/spinner';
import { useTranslate } from '@tolgee/react';
import { Text } from '@lbc-toolkit/text';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faWarning } from '@fortawesome/pro-solid-svg-icons';

type LoadingAnimation = {
    isLoading?: boolean;
    isError?: boolean;
    isInformation?: boolean;
    loading?: string;
    error?: string;
    information?: string;
    isAbsolute?: boolean;
    className?: string;
    style?: React.CSSProperties;
    children?: React.ReactNode;
}

const StatusDisplay = ({
    isLoading = false,
    isError = false,
    isInformation = false,
    loading,
    error,
    information,
    isAbsolute = false,
    className,
    style,
    children
}: LoadingAnimation) => {
    const {t} = useTranslate();

    const defaultLoadingText = t('loadingData');
    const defaultErrorText = t('errorOccured');
    const defaultInformationText = t('noDataAvailable');

    if(!isLoading && !isError && !isInformation) {
        if(children)
            return children;
        else
            return null;
    }

    if(isAbsolute) {
        style = {
            ...style,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
        };
    }

    return (<div style={{...style}} className={className}>
        {isError ? <FontAwesomeIcon style={{ height: '30px' }} icon={faWarning as IconProp} swapOpacity /> : 
        isInformation ? <FontAwesomeIcon style={{ height: '30px' }} icon={faInfoCircle as IconProp} swapOpacity /> :
        isLoading && <Spinner spin={true} />}

        <Text className='pt-2' type='h6'>
            {isError ? (error || defaultErrorText):
            isInformation ? (information || defaultInformationText) :
            isLoading && (loading || defaultLoadingText)}
        </Text>
    </div>);
}

export default StatusDisplay;